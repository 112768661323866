<template>
  <div class="page home">
    <div class="home__title">

      <div class="home__title__content">
        <h1>Bio Degradable Packaging</h1>
        <h2>The smart green concept with a love for nature</h2>
        <p>Wij bieden u ecologische, trendvolle en inspirerende oplossingen aan om op een aangename en bewuste manier over te
          stappen naar het concept van <b>de toekomst</b>.</p>
        <router-link to="/contact" class="button button--cta">Neem contact met ons op</router-link>
      </div>
      <div class="home__title__img">
        <img src="../assets/img/materialen.jpg" alt="bamboo">
      </div>
    </div>
    <div class="pillars">
      <div class="pillars__item">
        <div class="pillars__item__icon">
          <fa icon="leaf"></fa>
        </div>
        <h2>Ecologische innovatie</h2>
        <p>Het BIO degradable programma omvat uitsluitend producten gemaakt van <b>hernieuwbare plantaardige grondstoffen</b>.
          Alle gebruikte materialen zijn ofwel afvalproducten van de landbouw ofwel afkomstig van snelgroeiende
          hernieuwbare grondstoffen van planten.</p>
        <p>Wij investeren voortdurend in het uitbreiden van ons assortiment en het ontwikkelen van nieuwe producten.</p>
      </div>
      <div class="pillars__item">
        <div class="pillars__item__icon">
          <fa icon="boxes"></fa>
        </div>
        <h2>Ruim assortiment</h2>
        <p>Wij bieden een uitgebreid gamma producten aan: bestek, dozen, frituurbakjes, rietjes, bekers, herbruikbare
          zakken... </p>
        <p>Onze producten zijn, afhankelijk van het materiaal, geschikt voor koud en warm gebruik, vloeistoffen, en
          zowel in de diepvries, de microgolf of een gewone oven te gebruiken.</p>
      </div>
      <div class="pillars__item">
        <div class="pillars__item__icon">
          <fa icon="pencil-ruler"></fa>
        </div>
        <h2>Maatwerk</h2>
        <p>Naast onze standaardverpakkingen, bieden wij ook <b>gepersonaliseerde verpakkingen</b> aan, in al hun vormen en
          conform uw bedrijfscultuur. Stuur ons uw ontwerp door en wij zorgen voor de correcte bedrukking.</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home'
}
</script>
