<template>
<footer class="footer">
  <div class="footer__top">
    <p>Bio Degradable Packaging | <a href="tel:+32472940227">+32 472 94 02 27</a> | <a href="mailto:contact@biodp.eu">contact@biodp.eu</a>  |  Wiedauwkaai 99B, 9000 Gent, België | BE0886412625</p>
  </div>
  <div class="footer__bottom">
    <p>Copyright Bio Degradable Packaging</p>
  </div>
</footer>
</template>

<script>
export default {
name: "TheFooter"
}
</script>